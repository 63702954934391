.App {
  text-align: center;
  color: white;
  overflow: hidden;
  background: url("assets/background.jpg") top right;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: inherit;
  box-shadow: inset 0 0 0 3000px rgba(0,0,0, 0.8);
  right: 0;
  width: 100%;
  overflow: auto;
}

.App-header {
  font-size: calc(5px + 2vmin);
  max-width: 1000px;
  position: relative;
  align-self: center;
  color: whitesmoke;
}

.App-title {
  font-size: calc(15px + 2vmin);
}

.App-link {
  color: #FC6600;
}

.App-footer {
  position: fixed;
  font-size: calc(2vmin);
  bottom: 0;
  width: 100%;
  background-color: #222222;
}

.App-footer-header {
  font-size: calc(2px + 2vmin);
}

.App-image-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100%;
  width: calc(100% - 30px);
  font-size: 0;
}

.App-image-overlay:hover {
  font-size: inherit;
  box-shadow: inset 0 0 0 3000px rgba(0,0,0, 0.5);
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
